export const colorRedDark = "#8B0000";
export const colorRedLight = "#d19999";
export const colorRedLighter = "#f6ebeb";
export const colorGreenLight = "#99c199";
export const colorGreenLighter = "#e0ece0";
export const colorGreenDark = "#006400";
export const colorBlueDark = "#0047AB";
export const colorBlue = "#2a81ff";
export const colorBlueLight = "#b3d2ff";
export const colorPurple = "#8019c0";
export const colorMidGray = "#5b593c";
export const colorLight = "#D4D4D4";
export const colorLightGray = "#E8E8E8";
export const colorEmpty = "#FCFCFC";

export const colorLikeGreen = "#14A44D";
export const colorMessagesBlue = "#248bf5";

export const colorMaleMatching = colorLikeGreen;
export const colorFemaleMatching = colorPurple;

export const colorFuegoStatsBrown = "#2F1B00";
export const colorFuegoStatsOrange = "#FF6D00";
export const colorFuegoStatsYellow = "#FFCF4A";

// ux colors
export const colorSpinner = colorFuegoStatsBrown;

export const colorDrop = colorFuegoStatsBrown;
export const colorHalftime = colorFuegoStatsYellow;

// convert a value from color[0] to color[4]
export function valueToImpartialGradient({
  value,
  min = -1,
  max = 1,
  midpoint,
}: {
  value: number;
  min?: number;
  max?: number;
  midpoint?: number;
}): string {
  return valueToGradient({
    value,
    colors: ["#F5DFC7", "#BF997C", "#845F46", "#614735", "#422916"],
    min,
    max,
    midpoint,
  });
}

// convert a value from -1 (red) to 0 (gray) to +1 (green)
export function valueToRedGreenGradient({
  value,
  min = -1,
  max = 1,
  midpoint,
}: {
  value: number;
  min?: number;
  max?: number;
  midpoint?: number;
}): string {
  return valueToGradient({
    value,
    colors: [
      colorGreenDark,
      colorGreenLight,
      colorLight,
      colorRedLight,
      colorRedDark,
    ],
    threshold: 0.35,
    min,
    max,
    midpoint,
  });
}

// convert a value mapped within a green gradient
export const valueToGreenGradient = ({
  value,
  min = -1,
  max = 1,
}: {
  value: number;
  min?: number;
  max?: number;
}) =>
  valueToTwoColorGradient({
    value,
    colors: [colorGreenDark, colorGreenLighter],
    min,
    max,
  });

export const valueToRedGradient = ({
  value,
  min = -1,
  max = 1,
}: {
  value: number;
  min?: number;
  max?: number;
}) =>
  valueToTwoColorGradient({
    value,
    colors: [colorRedDark, colorRedLighter],
    min,
    max,
  });

export const valueToGrayRedGradient = ({
  value,
  min = -1,
  max = 1,
}: {
  value: number;
  min?: number;
  max?: number;
}) =>
  value
    ? valueToTwoColorGradient({
        value,
        colors: [colorRedDark, colorLightGray],
        min,
        max,
      })
    : value === 0
    ? colorLightGray
    : null;

// convert a value mapped to a 2 hex colors
const valueToTwoColorGradient = ({
  value,
  colors,
  min = -1,
  max = 1,
}: {
  value: number;
  colors: string[];
  min?: number;
  max?: number;
}) =>
  valueToGradient({
    // normalize the value to the target range [0.5, 1]
    value: Math.max(
      0.5,
      max === min
        ? 0.75
        : Math.min(((value - min) / (max - min)) * (1 - 0.5) + 0.5, 1)
    ),
    colors,
    threshold: 0.5,
    min: -1,
    max: 1,
  });

// convert a value mapped to an array of 5 hex colors
export function valueToGradient({
  value,
  colors,
  threshold = 0.5,
  min = -1,
  max = 1,
  midpoint,
}: {
  value: number;
  colors: string[];
  threshold?: number;
  min?: number;
  max?: number;
  midpoint?: number;
}): string {
  let normalizedValue;

  if (typeof value === "undefined" || isNaN(value)) {
    return colorEmpty;
  }

  if (midpoint !== undefined) {
    if (value >= midpoint) {
      normalizedValue =
        max === midpoint ? 1 : (value - midpoint) / (max - midpoint);
    } else {
      normalizedValue =
        min === midpoint ? 0 : (value - midpoint) / (midpoint - min);
    }
  } else {
    normalizedValue = max === min ? 0 : ((value - min) / (max - min)) * 2 - 1;
  }

  if (normalizedValue >= threshold) {
    return colorMixer(
      colors[0],
      colors[1],
      (normalizedValue - threshold) / (1 - threshold)
    );
  } else if (normalizedValue > 0) {
    return colorMixer(colors[1], colors[2], normalizedValue / threshold);
  } else if (normalizedValue > -1 * threshold) {
    return colorMixer(
      colors[2],
      colors[3],
      (normalizedValue + threshold) / threshold
    );
  }
  return colorMixer(
    colors[3],
    colors[4],
    (1 + normalizedValue) / (1 - threshold)
  );
}

function colorMixer(color1: string, color2: string, amountToMix: number) {
  const color1Rgb = hexToRgb(color1);
  const color2Rgb = hexToRgb(color2);
  const r = Math.round(
    colorChannelMixer(color1Rgb.r, color2Rgb.r, amountToMix)
  );
  const g = Math.round(
    colorChannelMixer(color1Rgb.g, color2Rgb.g, amountToMix)
  );
  const b = Math.round(
    colorChannelMixer(color1Rgb.b, color2Rgb.b, amountToMix)
  );
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: result ? parseInt(result[1], 16) : 128,
    g: result ? parseInt(result[2], 16) : 128,
    b: result ? parseInt(result[3], 16) : 128,
  };
}

// colorChannelA and colorChannelB are ints ranging from 0 to 255
function colorChannelMixer(
  colorChannelA: number,
  colorChannelB: number,
  amountToMix: number
) {
  var channelA = colorChannelA * amountToMix;
  var channelB = colorChannelB * (1 - amountToMix);
  return channelA + channelB;
}

function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function hexToLuminance({
  r,
  g,
  b,
}: {
  r: number;
  g: number;
  b: number;
}): number {
  // normalize the RGB values to 0-1
  let a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}

export const contrastingHexColor = (
  hex: string,
  threshold: number = 0.42
): string =>
  hex === null || typeof hex === "undefined"
    ? "#000000"
    : hexToLuminance(hexToRgb(hex)) < threshold
    ? "#FFFFFF"
    : "#000000";
